import { req } from "../js/config";
import * as base from "../js/base";


const courseFee = 650;
/*const CDIelts = 300;
const PBIelts = 300;
const EORFee = 250;
const ExpressShipping = 50;
const AdditionalTRF = 25;*/
const CDIelts = 317.70;
const PBIelts = 317.70;
const EORFee = 250;
const ExpressShipping = 53.1;
const AdditionalTRF = 25;
const AdditionalTRFPlusExpressShipping = 80;
const IeltsTransfer = 90;
const UniversityPreprationProgramApplication = 100;
const EnglishLiteracyDevelopmentProgramApplication = 100;
const dateForOrder = new Date();
const orderNumber = "XYNA" + (dateForOrder.getFullYear().toString()).concat((dateForOrder.getMonth() + 1).toString()).concat(dateForOrder.getDate().toString()).concat(dateForOrder.getHours().toString()).concat(dateForOrder.getMinutes().toString()).concat(dateForOrder.getSeconds().toString());
console.log("🚀 ~ file: main.js ~ line 13 ~ orderNumber", orderNumber)
let TotalFee = 0;
let SubTotal = 0;
let HST = 0;


let FirstEventIncrement = 0;
let ThreeDFormSubmitted = false;

const Initialize = async () => {
  console.log("Initialize ")
  HidePageControls();
}

const HidePageControls = async () => {
  console.log("HidePageControls ")
  document.querySelector('#CourseFeeLocation').style.display = "none";
  document.querySelector('#PBIeltsFeeLocation').style.display = "none";
  document.querySelector('#CDIeltsFeeLocation').style.display = "none";
  document.querySelector('#IeltsEORFeeLocation').style.display = "none";
  document.querySelector('#IeltsExpressShippingFeeLocation').style.display = "none";
  document.querySelector('#AdditionalTRFRequestFeeLocation').style.display = "none";
  document.querySelector('#AdditionalTRFRequestPlusExpressShippingFeeLocation').style.display = "none";
  document.querySelector('#IeltsTransferFeeLocation').style.display = "none";
  document.querySelector('#CustomFeeLocation').style.display = "none";
  document.querySelector('#UPPApplicationFeeLocation').style.display = "none";
  document.querySelector('#ELDPApplicationFeeLocation').style.display = "none";
  document.querySelector('#td_SubTotal').innerHTML = "$0.00"
  document.querySelector('#td_Total').innerHTML = "$0.00"
  document.querySelector('#td_HST').innerHTML = "$0.00"
  document.querySelector('#tr_CustomFee').style.display = "none";
  document.querySelector('#testDate').style.display = "none";
  document.querySelector('#successMessageRow').style.display = "none";
  document.querySelector('#displayBox').style.display = "none";
  TotalFee = 0;
  SubTotal = 0;
  HST = 0;
}

const ClearPageControls = async () => {
  console.log("ClearPageControls ")
  document.querySelector('#CourseFeeLocation').selectedIndex = 0;
  document.querySelector('#PBIeltsFeeLocation').selectedIndex = 0;
  document.querySelector('#CDIeltsFeeLocation').selectedIndex = 0;
  document.querySelector('#IeltsEORFeeLocation').selectedIndex = 0;
  document.querySelector('#IeltsExpressShippingFeeLocation').selectedIndex = 0;
  document.querySelector('#AdditionalTRFRequestFeeLocation').selectedIndex = 0;
  document.querySelector('#AdditionalTRFRequestPlusExpressShippingFeeLocation').selectedIndex = 0;
  document.querySelector('#IeltsTransferFeeLocation').selectedIndex = 0;
  document.querySelector('#CustomFeeLocation').selectedIndex = 0;
  document.querySelector('#UPPApplicationFeeLocation').selectedIndex = 0;
  document.querySelector('#ELDPApplicationFeeLocation').selectedIndex = 0;
  document.querySelector('#td_SubTotal').innerHTML = "$0.00"
  document.querySelector('#td_Total').innerHTML = "$0.00"
  document.querySelector('#td_HST').innerHTML = "$0.00"


  document.querySelector('#firstNameField').value = "";
  document.querySelector('#lastNameField').value = "";
  document.querySelector('#phoneField').value = "";
  document.querySelector('#emailField').value = "";
  document.querySelector('#noteField').value = "";
  document.querySelector('#testDateField').value = "";
  document.querySelector('#cardholderNameField').value = "";
  document.querySelector('#expiryDateField').value = "";
  document.querySelector('#cardNumberField').value = "";
  document.querySelector('#securityCodeField').value = "";

  document.querySelector('#FeeType').selectedIndex = 0;
  document.querySelector('#td_FeeType').innerHTML = "...";
  document.querySelector('#td_Fee').innerHTML = "...";
  document.querySelector('#customFeeField').value = 0;

  TotalFee = 0;
  SubTotal = 0;
  HST = 0;
  HidePageControls();
}

const CalculateTotal = async (fee, isHst) => {
  document.querySelector('#td_Fee').innerHTML = '$' + fee;
  document.querySelector('#td_SubTotal').innerHTML = '$' + fee;
  SubTotal = fee;
  if (isHst) {
    document.querySelector('#td_HST').innerHTML = '$' + parseFloat(fee * .13).toFixed(2);
    document.querySelector('#td_Total').innerHTML = '$' + parseFloat(fee * 1.13).toFixed(2);
    HST = parseFloat(fee * .13).toFixed(2);
    TotalFee = parseFloat(fee * 1.13).toFixed(2);
  }
  else {
    document.querySelector('#td_HST').innerHTML = '$' + 0.00;
    document.querySelector('#td_Total').innerHTML = '$' + fee;
    HST = 0.00;
    TotalFee = fee;
  }
}

document.querySelector('#FeeType').addEventListener("change", onFeeTypeChange);

function onFeeTypeChange() {
  var FT = document.querySelector('#FeeType');
  if (FT != null) {
    HidePageControls();
    var FeeType = FT.value;
    console.log("FeeType", FeeType);
    switch (FeeType) {
      case 'Course Fee':
        document.querySelector('#CourseFeeLocation').style.display = "block";
        document.querySelector('#td_FeeType').innerHTML = 'Course Fee';
        CalculateTotal(courseFee, 0);
        break;
      case 'Paper Based IELTS Test Fee':
        document.querySelector('#PBIeltsFeeLocation').style.display = "block";
        document.querySelector('#testDate').style.display = "block";
        document.querySelector('#td_FeeType').innerHTML = 'Paper Based IELTS Test Fee';
        CalculateTotal(PBIelts, 1);
        break;
      case 'Computer Delivered IELTS Test Fee':
        document.querySelector('#CDIeltsFeeLocation').style.display = "block";
        document.querySelector('#testDate').style.display = "block";
        document.querySelector('#td_FeeType').innerHTML = 'Computer Delivered IELTS Test Fee';
        CalculateTotal(CDIelts, 1);
        break;
      case 'IELTS EOR Fee':
        document.querySelector('#IeltsEORFeeLocation').style.display = "block";
        document.querySelector('#testDate').style.display = "block";
        document.querySelector('#td_FeeType').innerHTML = 'IELTS EOR Fee';
        CalculateTotal(EORFee, 1);
        break;
      case 'IELTS Express Shipping Fee':
        document.querySelector('#IeltsExpressShippingFeeLocation').style.display = "block";
        document.querySelector('#testDate').style.display = "block";
        document.querySelector('#td_FeeType').innerHTML = 'IELTS Express Shipping Fee';
        CalculateTotal(ExpressShipping, 1);
        break;
      case 'Additional TRF Request Fee':
        document.querySelector('#AdditionalTRFRequestFeeLocation').style.display = "block";
        document.querySelector('#testDate').style.display = "block";
        document.querySelector('#td_FeeType').innerHTML = 'Additional TRF Request Fee';
        CalculateTotal(AdditionalTRF, 1);
        break;
        case 'Additional TRF Request Plus Express Shipping Fee':
        document.querySelector('#AdditionalTRFRequestPlusExpressShippingFeeLocation').style.display = "block";
        document.querySelector('#testDate').style.display = "block";
        document.querySelector('#td_FeeType').innerHTML = 'Additional TRF Request Plus Express Shipping Fee';
        CalculateTotal(AdditionalTRFPlusExpressShipping, 0);
        break;
        case 'IELTS Transfer Fee':
        document.querySelector('#IeltsTransferFeeLocation').style.display = "block";
        document.querySelector('#testDate').style.display = "block";
        document.querySelector('#td_FeeType').innerHTML = 'Ielts Transfer Fee';
        CalculateTotal(IeltsTransfer, 0);
        break;
      case 'Custom Fee':
        document.querySelector('#CustomFeeLocation').style.display = "block";

        document.querySelector('#tr_CustomFee').style.display = "table-row";
        document.querySelector('#td_FeeType').innerHTML = 'Custom Fee';
        document.querySelector('#td_Fee').innerHTML = '$0.00';

        break;
        case 'University Prepration Program Application Fee':
          document.querySelector('#UPPApplicationFeeLocation').style.display = "block";
          document.querySelector('#testDate').style.display = "none";
          document.querySelector('#td_FeeType').innerHTML = 'University Prepration Program Application Fee';
          CalculateTotal(UniversityPreprationProgramApplication, 0);
          break;
          case 'English Literacy Development Program Application Fee':
            document.querySelector('#ELDPApplicationFeeLocation').style.display = "block";
            document.querySelector('#testDate').style.display = "none";
            document.querySelector('#td_FeeType').innerHTML = 'English Literacy Development Program Application Fee';
            CalculateTotal(EnglishLiteracyDevelopmentProgramApplication, 0);
            break;
      default:
        break;
    }

  }

}

const ProcessPaymentLogic = async (token) => {

  //show loading icon
  base.renderSpinnerButton(document.querySelector('#paynowBtn'));



  //disable all other controls.

  //get data from the controls.
  var payInfo = {};

  payInfo.PaymentType = document.querySelector('#FeeType').value;
  switch (payInfo.PaymentType) {
    case 'Course Fee':

      payInfo.Location = document.querySelector('#courseLocationField').value;

      break;
    case 'Paper Based IELTS Test Fee':

      payInfo.Location = document.querySelector('#pbIeltsLocationField').value;


      break;
    case 'Computer Delivered IELTS Test Fee':

      payInfo.Location = document.querySelector('#cdIeltsLocationField').value;


      break;
    case 'IELTS EOR Fee':

      payInfo.Location = document.querySelector('#ieltsEORLocationField').value;


      break;
    case 'IELTS Express Shipping Fee':

      payInfo.Location = document.querySelector('#expressShippingLocationField').value;


      break;
    case 'Additional TRF Request Fee':

      payInfo.Location = document.querySelector('#trfRequestLocationField').value

      break;
    case 'Additional TRF Request Plus Express Shipping Fee':

      payInfo.Location = document.querySelector('#trfRequestPlusExpressShippingLocationField').value

    break;
    case 'IELTS Transfer Fee':

      payInfo.Location = document.querySelector('#ieltsTransferLocationField').value

    break;
    case 'Custom Fee':

      payInfo.Location = document.querySelector('#customLocationField').value;


      break;
      case 'University Prepration Program Application Fee':

      payInfo.Location = document.querySelector('#uppApplicationLocationField').value

    break;
    case 'English Literacy Development Program Application Fee':

    payInfo.Location = document.querySelector('#eldpApplicationLocationField').value

  break;
    default:
      break;
  }
  payInfo.FirstName = document.querySelector('#firstNameField').value;
  payInfo.LastName = document.querySelector('#lastNameField').value;
  payInfo.Phone = document.querySelector('#phoneField').value;
  payInfo.Email = document.querySelector('#emailField').value;
  payInfo.Notes = document.querySelector('#noteField').value;
  payInfo.TestDate = document.querySelector('#testDateField').value;

  payInfo.Amount = TotalFee,
    payInfo.HST = HST,
    payInfo.SubTotal = SubTotal;
  payInfo.CC_Name = document.querySelector('#cardholderNameField').value;
  payInfo.CC_Exp = document.querySelector('#expiryDateField').value;
  payInfo.CC_Number = document.querySelector('#cardNumberField').value;
  payInfo.CC_CVD = document.querySelector('#securityCodeField').value;
  payInfo.token = token;
  console.log('PayInfo', payInfo);
  //post to the backend

  await req.post('payment/processpayment', payInfo)
    .then(async function (response) {
      if (response.response != undefined) {
        if (response.response.status === 500) {
          console.log('Payment declined !!!!! ');
          console.log(response);
          console.log(response.response);
          base.notify_Error("Something went wrong with the payment, please contact XYNA.", "Payment Declined!!!")
        }
      }
      else {
        if (response.status === 200) {
          console.log('payment successfull');
          base.notify_Success("Payment Successful", "Success!");
          await ClearPageControls();
          document.querySelector('#successMessageRow').style.display = "block";
          document.querySelector('#successMessageRow_Message').innerHTML = "Your payment was successful! You will recieve a receipt in your email (Please check spam / junk folder if you don't find receipt in your inbox). ";

          document.querySelector('#paymentPageRow').style.display = "none";
        }
      }


    }).catch(function (error) {
      console.log(error);
      base.notify_Error("Something went wrong with the payment, please contact XYNA.", "Payment Error!!!")
    });
  //base.notify_Success("Payment Successful", "Success!");
  base.clearSpinnerButton(document.querySelector('#paynowBtn'));
  //dispaly the message

}

const PreProcessPaymentLogic = async (token) => {

  //show loading icon
  base.renderSpinnerButton(document.querySelector('#paynowBtn'));



  //disable all other controls.

  //get data from the controls.
  var payInfo = {};

  payInfo.PaymentType = document.querySelector('#FeeType').value;
  switch (payInfo.PaymentType) {
    case 'Course Fee':

      payInfo.Location = document.querySelector('#courseLocationField').value;

      break;
    case 'Paper Based IELTS Test Fee':

      payInfo.Location = document.querySelector('#pbIeltsLocationField').value;


      break;
    case 'Computer Delivered IELTS Test Fee':

      payInfo.Location = document.querySelector('#cdIeltsLocationField').value;


      break;
    case 'IELTS EOR Fee':

      payInfo.Location = document.querySelector('#ieltsEORLocationField').value;


      break;
    case 'IELTS Express Shipping Fee':

      payInfo.Location = document.querySelector('#expressShippingLocationField').value;


      break;
    case 'Additional TRF Request Fee':

      payInfo.Location = document.querySelector('#trfRequestLocationField').value

      break;
       case 'Additional TRF Request Plus Express Shipping Fee':

      payInfo.Location = document.querySelector('#trfRequestPlusExpressShippingLocationField').value

    break;
    case 'IELTS Transfer Fee':

      payInfo.Location = document.querySelector('#ieltsTransferLocationField').value

    break;
    case 'Custom Fee':

      payInfo.Location = document.querySelector('#customLocationField').value;


      break;
    default:
      break;
  }
  payInfo.FirstName = document.querySelector('#firstNameField').value;
  payInfo.LastName = document.querySelector('#lastNameField').value;
  payInfo.Phone = document.querySelector('#phoneField').value;
  payInfo.Email = document.querySelector('#emailField').value;
  payInfo.Notes = document.querySelector('#noteField').value;
  payInfo.TestDate = document.querySelector('#testDateField').value;

  payInfo.Amount = TotalFee,
    payInfo.HST = HST,
    payInfo.SubTotal = SubTotal;
  payInfo.CC_Name = document.querySelector('#cardholderNameField').value;
  payInfo.CC_Exp = document.querySelector('#expiryDateField').value;
  payInfo.CC_Number = document.querySelector('#cardNumberField').value;
  payInfo.CC_CVD = document.querySelector('#securityCodeField').value;
  payInfo.token = token;

  payInfo.OrderNumber = orderNumber;

  console.log('PayInfo', payInfo);
  //post to the backend

  await req.post('payment/preprocesspayment', payInfo)
    .then(async function (response) {
      if (response.response != undefined) {
        if (response.response.status === 500) {
          console.log('Payment declined !!!!! ');
          console.log(response);
          console.log(response.response);
          base.notify_Error("Something went wrong with the payment, please contact XYNA.", "Payment Declined!!!")
        }
      }
      else {
        if (response.status === 200) {
          console.log('pre process payment successfull');
          //base.notify_Success("Payment Successful", "Success!");
          //await ClearPageControls();
          //  document.querySelector('#successMessageRow').style.display = "block";
          // document.querySelector('#successMessageRow_Message').innerHTML = "Pre processing is done. data received ";

          const threeDSMethodURL = response.data[0];
          const threeDSMethodData = response.data[1];


          let displayBox = document.querySelector('#hiddendisplayBox');
          let iframe = document.createElement('iframe');
          iframe.classList.add('hidden');
          iframe.name = "threeDSMethodIframe";
          iframe.id = '3dframe'
          displayBox.appendChild(iframe);

          iframe.addEventListener("load", async function (e) {

            // hide frame and show payment page

            //  setTimeout(async () => {
            // console.log("waiting 10 seconds before the cnext call");
            if (ThreeDFormSubmitted == false) {

              ThreeDFormSubmitted = true;
              console.log('Initiating call');


              grecaptcha.ready(function () {
                grecaptcha.execute('6LeHbFkaAAAAAMBWIOhBNaXNy9roQGwThYe33UzY', { action: 'submit' }).then(async function (token) {
                  payInfo.token = token;

                  await req.post('payment/3DProcessPayment', payInfo)
                    .then(async function (response) {
                      if (response.response != undefined) {
                        if (response.response.status === 500) {
                          console.log('second step failure ');
                          console.log(response);
                          console.log(response.response);
                          base.notify_Error("Something went wrong with the payment, please contact XYNA.", "Payment Declined!!!")
                        }
                      }
                      else {
                        if (response.status === 200) {

                          console.log('3ds authentication request compelted');
                          console.log(response);
                          // base.notify_Success("Payment Successful", "Success!");
                          //await ClearPageControls();
                          //document.querySelector('#successMessageRow').style.display = "block";
                          // document.querySelector('#successMessageRow_Message').innerHTML = "3ds authentication request compelted";
                          if (response.data != '' && response.data != null && response.data != 'undefined') {
                            const challengedURL = typeof response.data !== 'undefined' ? response.data[0] : '';
                            const challengeData = typeof response.data !== 'undefined' ? response.data[1] : '';

                            if (challengedURL != '') {
                              document.querySelector('#paymentPageRow').style.display = "none";
                              let displayBox = document.querySelector('#displayBox');
                              displayBox.style.display = "flex";
                              let ciframe = document.createElement('iframe');
                              ciframe.id = 'skframe'
                              ciframe.classList.add('col-lg-12');
                              ciframe.classList.add('col-md-12');
                              ciframe.classList.add('col-sm-12');
                              ciframe.name = "challengeIframe";
                              displayBox.appendChild(ciframe);

                              ciframe.addEventListener("load", function (e) {

                                console.log("🚀 ~ file: main.js ~ line 433 ~ FirstEventIncrement", FirstEventIncrement)


                                console.log('ciframe load event ');
                                if (FirstEventIncrement > 1) {

                                  // hide frame and show payment page
                                  displayBox.style.display = "none";

                                  document.querySelector('#paymentPageRow').style.display = "flex";

                                  console.log('******* Final testing********');

                                  grecaptcha.ready(function () {
                                    grecaptcha.execute('6LeHbFkaAAAAAMBWIOhBNaXNy9roQGwThYe33UzY', { action: 'submit' }).then(async function (token) {
                                      // Add your logic to submit to  backend server.
                                      payInfo.token = token;
                                      await req.post('payment/ThreeDSPaymentCAVVLookup', payInfo)
                                        .then(async function (response) {
                                          if (response.response != undefined) {
                                            if (response.response.status === 500) {
                                              console.log('final step failure ');
                                              console.log(response);
                                              console.log(response.response);
                                              base.notify_Error("Something went wrong with the payment, please contact XYNA.", "Payment Declined!!!")
                                            }
                                          }
                                          else if (response.status === 200) {
                                            console.log('payment successfull');
                                            base.notify_Success("Payment Successful", "Success!");
                                            await ClearPageControls();
                                            document.querySelector('#successMessageRow').style.display = "block";
                                            document.querySelector('#successMessageRow_Message').innerHTML = "Your payment was successful! You will recieve a receipt in your email (Please check spam / junk folder if you don't find receipt in your inbox). ";

                                            document.querySelector('#paymentPageRow').style.display = "none";
                                          }
                                        }).catch(function (error) {
                                          console.log(error);
                                          base.notify_Error("Something went wrong with the payment, please contact XYNA.", "Payment Error!!!")
                                        });
                                    });
                                  });

                                }
                                FirstEventIncrement++;
                                console.log(e.target);

                                //console.log(document.querySelector('#skframe'));

                              });


                              let formChallenge = document.querySelector('#challengeForm');

                              document.querySelector('#creq').value = challengeData;
                              console.log('sending data to the URL');
                              formChallenge.action = challengedURL;
                              formChallenge.target = 'challengeIframe'; // id of iframe
                              formChallenge.method = 'post';
                              formChallenge.submit();
                              console.log('form data submitted');




                            }
                          }
                          else {
                            console.log('NO challenge required.');
                            console.log('payment successfull');
                            base.notify_Success("Payment Successful", "Success!");
                            await ClearPageControls();
                            document.querySelector('#successMessageRow').style.display = "block";
                            document.querySelector('#successMessageRow_Message').innerHTML = "Your payment was successful! You will recieve a receipt in your email (Please check spam / junk folder if you don't find receipt in your inbox). ";

                            document.querySelector('#paymentPageRow').style.display = "none";
                          }



                          //document.querySelector('#paymentPageRow').style.display = "none";
                        }
                      }
                    }).catch(function (error) {
                      console.log(error);
                      base.notify_Error("Something went wrong with the payment, please contact XYNA.", "Payment Error!!!")
                    });

                });
              });


              // console.log(e.target);
            }
          });

          let form = document.querySelector('#threeDSMethodForm');
          document.querySelector('#threeDSMethodData').value = threeDSMethodData;
          console.log('sending data to the URL');
          form.action = threeDSMethodURL;
          form.target = 'threeDSMethodIframe'; // id of iframe
          form.method = 'post';
          form.submit();

          console.log('form data submitted, Now proceeding for the next step');


          //  document.querySelector('#paymentPageRow').style.display = "none";





          //}, 10000);

        }
      }


    }).catch(function (error) {
      console.log(error);
      base.notify_Error("Something went wrong with the payment, please contact XYNA.", "Payment Error!!!")
    });
  //base.notify_Success("Payment Successful", "Success!");
  //base.clearSpinnerButton(document.querySelector('#paynowBtn'));
  //dispaly the message

}

document.querySelector('#customFeeField').addEventListener("change", function (e) {
  CalculateTotal(document.querySelector('#customFeeField').value, 0);

});

document.querySelector('#paynowBtn').addEventListener("click", async function (e) {
  e.preventDefault();
  console.log('payment initialize');
  //validate fields
  if (ValidateForm()) {

    grecaptcha.ready(function () {
      grecaptcha.execute('6LeHbFkaAAAAAMBWIOhBNaXNy9roQGwThYe33UzY', { action: 'submit' }).then(async function (token) {
        // Add your logic to submit to  backend server.

        if (document.querySelector('#FeeType').value != null && document.querySelector('#FeeType').value != '') {
          if (document.querySelector('#FeeType').value == 'Custom Fee') {
            await PreProcessPaymentLogic(token);
          } else { await ProcessPaymentLogic(token); }
        }

        //await ProcessPaymentLogic(token);
        //  await PreProcessPaymentLogic(token);

      });
    });
  }
});

const ValidateForm = () => {
  if (document.querySelector('#FeeType').value == null || document.querySelector('#FeeType').value == '') {
    console.log('Fee Type is required!');
    base.notify_Error("Fee Type Missing ", "Fee Type is required!");
    return false;
  }
  if (document.querySelector('#FeeType').value != null || document.querySelector('#FeeType').value != '') {
    var FeeType = document.querySelector('#FeeType').value;
    switch (FeeType) {
      case 'Course Fee':
        if (document.querySelector('#courseLocationField').value == null || document.querySelector('#CourseFeeLocation').value == '') {
          console.log('Location is required!');
          base.notify_Error("Location Missing ", "Location is required!");
          return false;
        }
        break;
      case 'Paper Based IELTS Test Fee':
        console.log(document.querySelector('#pbIeltsLocationField').value);
        if (document.querySelector('#pbIeltsLocationField').value == null || document.querySelector('#pbIeltsLocationField').value == '' || document.querySelector('#pbIeltsLocationField').value == 'null') {
          console.log('Location is required!');
          base.notify_Error("Location Missing ", "Location is required!");
          return false;
        }
        if (document.querySelector('#testDateField').value == null || document.querySelector('#testDateField').value == '') {
          console.log('Test Date is required!');
          base.notify_Error("Test Date Missing ", "Test Date is required!");
          return false;
        }
        break;
      case 'Computer Delivered IELTS Test Fee':
        if (document.querySelector('#cdIeltsLocationField').value == null || document.querySelector('#cdIeltsLocationField').value == '' || document.querySelector('#cdIeltsLocationField').value == 'null') {
          console.log('Location is required!');
          base.notify_Error("Location Missing ", "Location is required!");
          return false;
        }
        if (document.querySelector('#testDateField').value == null || document.querySelector('#testDateField').value == '') {
          console.log('Test Date is required!');
          base.notify_Error("Test Date Missing ", "Test Date is required!");
          return false;
        }
        break;
      case 'IELTS EOR Fee':
        if (document.querySelector('#ieltsEORLocationField').value == null || document.querySelector('#IeltsEORFeeLocation').value == '') {
          console.log('Location is required!');
          base.notify_Error("Location Missing ", "Location is required!");
          return false;
        }

        break;
      case 'IELTS Express Shipping Fee':
        if (document.querySelector('#expressShippingLocationField').value == null || document.querySelector('#IeltsExpressShippingFeeLocation').value == '') {
          console.log('Location is required!');
          base.notify_Error("Location Missing ", "Location is required!");
          return false;
        }

        break;
      case 'Additional TRF Request Fee':
        if (document.querySelector('#trfRequestLocationField').value == null || document.querySelector('#AdditionalTRFRequestFeeLocation').value == '') {
          console.log('Location is required!');
          base.notify_Error("Location Missing ", "Location is required!");
          return false;
        }

        break;
        case 'Additional TRF Request Plus Express Shipping Fee':
        if (document.querySelector('#trfRequestPlusExpressShippingLocationField').value == null || document.querySelector('#AdditionalTRFRequestPlusExpressShippingFeeLocation').value == '') {
          console.log('Location is required!');
          base.notify_Error("Location Missing ", "Location is required!");
          return false;
        }

        break;
         case 'IELTS Transfer Fee':
        if (document.querySelector('#ieltsTransferLocationField').value == null || document.querySelector('#IeltsTransferFeeLocation').value == '') {
          console.log('Location is required!');
          base.notify_Error("Location Missing ", "Location is required!");
          return false;
        }

        break;
      case 'Custom Fee':
        if (document.querySelector('#customLocationField').value == null || document.querySelector('#CustomFeeLocation').value == '') {
          console.log('Location is required!');
          base.notify_Error("Location Missing ", "Location is required!");
          return false;
        }
        if (document.querySelector('#customFeeField').value == null || document.querySelector('#customFeeField').value == '') {
          console.log('Fee is required!');
          base.notify_Error("Fee Missing ", "Custom Fee is required!");
          return false;
        }

        break;
      default:
        break;
    }


  }
  if (document.querySelector('#firstNameField').value == null || document.querySelector('#firstNameField').value == '') {
    console.log('First Name is required!');
    base.notify_Error("First Name Missing ", "First Name is required!");
    return false;
  }
  if (document.querySelector('#lastNameField').value == null || document.querySelector('#lastNameField').value == '') {
    console.log('Last Name is required!');
    base.notify_Error("Last Name Missing ", "Last Name is required!");
    return false;
  }
  if (document.querySelector('#phoneField').value == null || document.querySelector('#phoneField').value == '') {
    console.log('Phone is required!');
    base.notify_Error("Phone Missing ", "Phone is required!");
    return false;
  }
  if (document.querySelector('#emailField').value == null || document.querySelector('#emailField').value == '') {
    console.log('Email is required!');
    base.notify_Error("Email Missing ", "Email is required!");
    return false;
  }
  if (document.querySelector('#cardholderNameField').value == null || document.querySelector('#cardholderNameField').value == '') {
    console.log('Cardholder Name is required!');
    base.notify_Error("Cardholder Name Missing ", "Cardholder Name is required!");
    return false;
  }
  if (document.querySelector('#cardNumberField').value == null || document.querySelector('#cardNumberField').value == '') {
    console.log('Card Number is required!');
    base.notify_Error("Card Number Missing ", "Card Number is required!");
    return false;
  }
  if ((document.querySelector('#cardNumberField').value != null || document.querySelector('#cardNumberField').value != '') && (document.querySelector('#cardNumberField').value.length != 16)) {

    console.log('Card Number must be 16 digits');
    base.notify_Error("Card Number must be 16 digits!", "Card Number is not valid");
    return false;
  }
  if (document.querySelector('#expiryDateField').value == null || document.querySelector('#expiryDateField').value == '') {
    console.log('Expiry Date is required!');
    base.notify_Error("Expiry Date Missing ", "Expiry Date is required!");
    return false;
  }
  if (document.querySelector('#expiryDateField').value != null || document.querySelector('#expiryDateField').value != '') {
    var month = document.querySelector('#expiryDateField').value.substring(0, 2)
    var year = document.querySelector('#expiryDateField').value.substring(2, 4)
    if (month < 0 || month > 12 || year < (new Date().getFullYear()).toString().substring(2, 4)) {
      console.log('Expiry Date is not valid!');
      base.notify_Error("Expiry Date is not valid! ", "Expiry Date Error!");
      return false;
    }




  }
  if (document.querySelector('#securityCodeField').value == null || document.querySelector('#securityCodeField').value == '') {
    console.log('Security Code is required!');
    base.notify_Error("Security Code Missing ", "Security Code is required!");
    return false;
  }
  return true;
}
Initialize();
